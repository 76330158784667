import React, { useEffect, useState } from 'react';
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-scroll';
import './css/homepage.css';
import WhiteUphasia from './img/WhiteUphasia.png';
import IpadMockup from './img/IpadMockup.png';

import Info1 from './img/Info1.png'
import Info2 from './img/Info2.png'
import Info3 from './img/Info3.png'
import Info4 from './img/Info4.png'

function Homepage() {
  const [scrolled, setScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState("home");

  const handleSetActive = (link) => {
    setActiveLink(link);
  };

  
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }

    if (offset > 200) {
      console.log("hey")
      setNavbarBg(true); // Apply background when scrolled past hero section
    } else {
      console.log("ho")
      setNavbarBg(false); // Remove background when in hero section
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Parallax scroll handling with IntersectionObserver
  useEffect(() => {
    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id;

            // Set different colors for different sections
            if (sectionId === "hero-section") {
              setNavbarBg("transparent");
            } else if (sectionId === "features-section2") {
              setNavbarBg("#f8f9fa"); // Light background for the features section
            } else {
              setNavbarBg("#133d57"); // Default dark color
            }
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the section is in view
    );

    const sections = document.querySelectorAll("section");
    sections.forEach((section) => sectionObserver.observe(section));

    return () => sections.forEach((section) => sectionObserver.unobserve(section));
  }, []);

  let navbarClasses = ['navbar-custom'];
  const [navbarBg, setNavbarBg] = useState(false); //   // Dynamic navbar classes based on scroll and section

  if (scrolled) {
    navbarClasses.push('scrolled');
  }
  if (navbarBg) {
    navbarClasses.push('bg-dark'); // Apply dark background when scrolled out of hero section
  }

  return (
    <div className="Homepage">
      {/* Navbar */}
      <Navbar
        variant="dark"
        expand="lg"
        className={navbarClasses.join(' ') + ' py-3 fixed-top'}
      >
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-center">
            <Row className="w-100">
              <Col className="text-center">
                <Link
                  to="hero-section"
                  smooth={true}
                  duration={800}
                  className={`nav-link ${activeLink === "home" ? "active" : ""}`}
                  onClick={() => handleSetActive("home")}
                >
                  Home
                </Link>
              </Col>
              <Col className="text-center">
                <Link
                  to="about-section"
                  smooth={true}
                  duration={800}
                  className={`nav-link ${activeLink === "about" ? "active" : ""}`}
                  onClick={() => handleSetActive("about")}
                >
                  About Us
                </Link>
              </Col>
              <Col className="text-center">
                <Link
                  to="contact-section"
                  smooth={true}
                  duration={800}
                  className={`nav-link ${activeLink === "contact" ? "active" : ""}`}
                  onClick={() => handleSetActive("contact")}
                >
                  Contact
                </Link>
              </Col>
            </Row>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Hero Section with Parallax */}
      <div className="hero-section text-center py-5" id="hero-section">
        <div className="hero-content">
          <div>
            <img src={WhiteUphasia} alt="Uphasialogo" className="hero-logo" />
          </div>
          <span><strong>"</strong>YOUR VOICE</span>
          <span><strong> WE'RE HERE TO LISTEN"</strong></span>
          <p>Application for communication disorders</p>
        </div>
      </div>

      {/* Parallax Section with IpadMockup */}
      <div className="parallax-section">
        <img src={IpadMockup} alt="Ipad Mockup" className="ipad-mockup" />
      </div>
      {/* New Block Section - Features or Services */}


      {/* Additional Features Section */}
      <div className="features-section2 text-center py-5" id="features-section2">

        <h1>Features of Our Application</h1>
        <img src={Info1} alt="infocenter" className="infocenter padding-2" style={{ width: '100vw', paddingBottom: '10%' }} />
        <img src={Info2} alt="infocenter" className="infocenter" />
        <img src={Info3} alt="infocenter" className="infocenter" />
        <h1>Our Team</h1>
        <img src={Info4} alt="infocenter" className="infocenter" />

      </div>


    </div>
  );
}

export default Homepage;
